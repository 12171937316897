import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { PricingInvestorSurveyAlt, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'insights';

export default function() {
  const { contentfulPricingAltPage } = useStaticQuery(graphql`
    {
      contentfulPricingAltPage(slug: { eq: "is-whats-included" }) {
        metaTitle
        metaDescription
        heroHeadline
        heroDescription {
          json
        }
        heroBackgroundImage {
          ...ImageSharpOriginal
        }
        pricingCards {
          cardType
          id
          cardSummary
          cardSummaryPlus
          inclusionHeadline
          pricePoint
          priceEyebrowText
          pricingFrequencyText1
          pricingFrequencyText2
          onboardingText {
            json
          }
          inclusionChecklist {
            text {
              childMarkdownRemark {
                html
                }
            }
          }
          incrementalFeesHeadline
          incrementalFeesList {
            text {
              text
            }
          }
          ctaText {
            json
          }
          primaryCta {
            ctaText
            ctaLink {
              ...NavigatorCtaLink
            }
          }
        }
        disclaimer
        spacerNavigator {
          headline
          blurb {
            json
          }
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulPricingAltPage.metaTitle,
      description: contentfulPricingAltPage.metaDescription
    },
    pricing: {
      headline: contentfulPricingAltPage.heroHeadline,
      body: contentfulPricingAltPage.heroDescription,
      background:
        contentfulPricingAltPage.heroBackgroundImage &&
        contentfulPricingAltPage.heroBackgroundImage.localFile.publicURL,
      tiers: contentfulPricingAltPage.pricingCards.map((tier) => ({
        id: tier.id,
        headline: tier.cardType,
        cardSummary: tier.cardSummary,
        cardSummaryPlus: tier.cardSummaryPlus,
        onboarding: tier.onboardingText,
        prices: [
          {
            eyebrow: tier.priceEyebrowText,
            footnote: tier.pricingFrequencyText2,
            frequency: tier.pricingFrequencyText1,
            price: tier.pricePoint,
          }
        ],
        featuresHeadline: tier.inclusionHeadline,
        features: tier.inclusionChecklist.map(({ text }) => text.childMarkdownRemark.html),
        feesHeadline: tier.incrementalFeesHeadline && tier.incrementalFeesHeadline,
        fees: tier.incrementalFeesList && 
              tier.incrementalFeesList.map(({ text }) => text.text),
        ctaText: tier.ctaText,
        primaryCta: tier.primaryCta && {
          buttonText: tier.primaryCta.ctaText,
          buttonUrl:
            tier.primaryCta.ctaLink &&
            linkResolver({
              type: tier.primaryCta.ctaLink[0].internal.type,
              slug: tier.primaryCta.ctaLink[0].redirectUrl || tier.primaryCta.ctaLink[0].slug,
              postPrefix,
            }),
        },
        secondaryCta: tier.secondaryCta && {
          buttonText: tier.secondaryCta.ctaText,
          buttonUrl:
            tier.secondaryCta.ctaLink &&
            linkResolver({
              type: tier.secondaryCta.ctaLink[0].internal.type,
              slug: tier.secondaryCta.ctaLink[0].redirectUrl || tier.secondaryCta.ctaLink[0].slug,
              postPrefix,
            }),
        },
      })),
      disclaimer: contentfulPricingAltPage.disclaimer,
    },
    navigator: {
      headline: contentfulPricingAltPage.headline,
      ctaText: contentfulPricingAltPage.ctaText,
      ctaLink: contentfulPricingAltPage.ctaLink,
    },
    cta: {
      headline: contentfulPricingAltPage.spacerNavigator.headline,
      buttonText: contentfulPricingAltPage.spacerNavigator.ctaText,
      buttonUrl:
        contentfulPricingAltPage.spacerNavigator.ctaLink &&
        linkResolver({
          type: contentfulPricingAltPage.spacerNavigator.ctaLink[0].internal.type,
          slug:
            contentfulPricingAltPage.spacerNavigator.ctaLink[0].redirectUrl ||
            contentfulPricingAltPage.spacerNavigator.ctaLink[0].slug,
          postPrefix,
        }),
      background: 'dark-1',
      noRule: true,
      primary: true,
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <PricingInvestorSurveyAlt doc={doc} Link={Link} />
    </Layout>
  );
}
